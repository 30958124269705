var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"div-content1",style:({
    transformOrigin: 'center top',
    transform: ("scale(" + _vm.scaleNum + "," + _vm.scaleNum + ")"),
    '-webkit-transform': ("scale(" + _vm.scaleNum + "," + _vm.scaleNum + ")"),
    '-moz-transform': ("scale(" + _vm.scaleNum + "," + _vm.scaleNum + ")"),
    '-o-transform': ("scale(" + _vm.scaleNum + "," + _vm.scaleNum + ")"),
    '-ms-transform': ("scale(" + _vm.scaleNum + "," + _vm.scaleNum + ")"),
  }),attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(7, 6, 43, 0.5)"}},[_c('div',{staticClass:"u-flex-col u-col-center"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"head_left",on:{"click":function($event){return _vm.$router.push('/visual_analysis/')}}}),_c('div',{staticClass:"head_center"},[_vm._v("企业信息监控")]),_c('div',{staticClass:"head_right"},[_vm._v(_vm._s(_vm.timeData))])]),_c('div',{staticClass:"visual_box"},[_c('div',{staticClass:"visual_box_left"},[_c('ChartModel',{attrs:{"title":"项目订单和收益情况"}},[_c('template',{slot:"chart"},[_c('StudentBarChart',{attrs:{"height":"100%","width":"100%","chartData":{
                label: ['10-20', '10-21', '10-22', '10-23'],
                planData: [10, 20, 34, 99],
                actualData: [10, 20, 34, 99],
              }}})],1)],2),_c('div',{staticClass:"project_rankingList"},[_c('div',{staticClass:"title mt-12"},[_vm._v("项目综合排行榜")]),_vm._l((_vm.projectList),function(item,index){return _c('div',{key:index,staticClass:"listBox"},[_c('img',{staticClass:"img",attrs:{"src":item.Icon,"alt":""}}),_c('div',{staticClass:"list_right"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.Name))]),_c('div',{staticClass:"text"},[_c('span',{staticClass:"leftTxt mt-9 mr-8"},[_vm._v("订单数")]),_c('span',{staticClass:"number mr-75"},[_vm._v(_vm._s(item.Indent)+"个")]),_c('span',{staticClass:"leftTxt mt-9 mr-8"},[_vm._v("订单数")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.Turnover)+"个")])])])])})],2)],1),_c('div',{staticClass:"visual_box_center"},[_c('div',{staticClass:"allChart mt-32"},_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"chartList mr-24"},[_c('div',{staticClass:"chart_right ml-24"},[_c('div',{staticClass:"name"},[_vm._v("企业"+_vm._s(index))]),_vm._m(0,true)])])}),0),_c('div',{staticClass:"histogram"},[_c('div',{staticClass:"title"},[_vm._v("企业分布情况情况")]),_c('div',{staticClass:"enterprise_chart"},[_c('ArrayBar1',{attrs:{"height":"100%","width":"100%","chartData":[]}})],1),_c('div',{staticClass:"enterprise_list"},[_c('div',{staticClass:"title mb-16"},[_vm._v("合作企业")]),_c('div',{staticClass:"enterpriseList"},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"enterprise_log mr-17 mb-16"},[_c('img',{staticClass:"img",attrs:{"src":"","alt":""}})])}),0)])])]),_c('div',{staticClass:"visual_box_right"},[_c('ChartModel',{attrs:{"title":"企业培训课程情况"}},[_c('template',{slot:"chart"},[_c('RankListChart',{attrs:{"height":"100%","width":"100%","chartData":[
                { Key: '收益', Value: 10 },
                { Key: '收益2', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 80 } ],"showBarNumber":5}})],1)],2),_c('div',{staticClass:"enterprise_mentor"},[_c('div',{staticClass:"title mt-12"},[_vm._v("占比")]),_c('div',{staticClass:"topChart"},[_c('StaffStudentChart',{attrs:{"height":"80%","width":"100%","chartData":[
                { Key: '老师占比', Value: 35 },
                { Key: '学生占比', Value: 65 } ]}}),_vm._m(1)],1),_c('div',{staticClass:"bottomChart"},[_c('StaffStudentChart',{attrs:{"height":"80%","width":"100%","chartData":[
                { Key: '老师占比', Value: 35 },
                { Key: '学生占比', Value: 65 } ]}}),_vm._m(2)],1)])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quantity"},[_c('span',{staticClass:"number"},[_vm._v("999")]),_vm._v("家")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_title"},[_c('div',{staticClass:"titleBox"},[_vm._v("学历分布")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_title"},[_c('div',{staticClass:"titleBox"},[_vm._v("学历分布")])])}]

export { render, staticRenderFns }