<template>
  <div
    v-loading="loading"
    class="div-content1"
    :style="{
      transformOrigin: 'center top',
      transform: `scale(${scaleNum},${scaleNum})`,
      '-webkit-transform': `scale(${scaleNum},${scaleNum})`,
      '-moz-transform': `scale(${scaleNum},${scaleNum})`,
      '-o-transform': `scale(${scaleNum},${scaleNum})`,
      '-ms-transform': `scale(${scaleNum},${scaleNum})`,
    }"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(7, 6, 43, 0.5)"
  >
    <!-- <ChartModel title="总体进度">
      <template slot="chart">
        <div class="pie"></div>
      </template>
    </ChartModel> -->
    <div class="u-flex-col u-col-center">
      <div class="head">
        <div class="head_left" @click="$router.push('/visual_analysis/')"></div>
        <div class="head_center">企业信息监控</div>
        <div class="head_right">{{ timeData }}</div>
      </div>
      <div class="visual_box">
        <div class="visual_box_left">
          <ChartModel title="项目订单和收益情况">
            <template slot="chart">
              <StudentBarChart
                height="100%"
                width="100%"
                :chartData="{
                  label: ['10-20', '10-21', '10-22', '10-23'],
                  planData: [10, 20, 34, 99],
                  actualData: [10, 20, 34, 99],
                }"
              />
            </template>
          </ChartModel>
          <div class="project_rankingList">
            <div class="title mt-12">项目综合排行榜</div>
            <div
              class="listBox"
              v-for="(item, index) in projectList"
              :key="index"
            >
              <img :src="item.Icon" class="img" alt="" />
              <div class="list_right">
                <div class="name">{{ item.Name }}</div>
                <div class="text">
                  <span class="leftTxt mt-9 mr-8">订单数</span
                  ><span class="number mr-75">{{ item.Indent }}个</span>
                  <span class="leftTxt mt-9 mr-8">订单数</span
                  ><span class="number">{{ item.Turnover }}个</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="visual_box_center">
          <div class="allChart mt-32">
            <div
              class="chartList mr-24"
              v-for="(item, index) in 3"
              :key="index"
            >
              <div class="chart_right ml-24">
                <div class="name">企业{{ index }}</div>
                <div class="quantity"><span class="number">999</span>家</div>
              </div>
            </div>
          </div>
          <div class="histogram">
            <div class="title">企业分布情况情况</div>
            <div class="enterprise_chart">
              <ArrayBar1 height="100%" width="100%" :chartData="[]" />
            </div>
            <div class="enterprise_list">
              <div class="title mb-16">合作企业</div>
              <div class="enterpriseList">
                <div
                  class="enterprise_log mr-17 mb-16"
                  v-for="(item, index) in 8"
                  :key="index"
                >
                  <img src="" class="img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="visual_box_right">
          <ChartModel title="企业培训课程情况">
            <template slot="chart">
              <RankListChart
                height="100%"
                width="100%"
                :chartData="[
                  { Key: '收益', Value: 10 },
                  { Key: '收益2', Value: 30 },
                  { Key: '收益', Value: 30 },
                  { Key: '收益', Value: 30 },
                  { Key: '收益', Value: 80 },
                ]"
                :showBarNumber="5"
              />
            </template>
          </ChartModel>
          <div class="enterprise_mentor">
            <div class="title mt-12">占比</div>
            <div class="topChart">
              <StaffStudentChart
                height="80%"
                width="100%"
                :chartData="[
                  { Key: '老师占比', Value: 35 },
                  { Key: '学生占比', Value: 65 },
                ]"
              />
              <div class="center_title">
                <div class="titleBox">学历分布</div>
              </div>
            </div>
            <div class="bottomChart">
              <StaffStudentChart
                height="80%"
                width="100%"
                :chartData="[
                  { Key: '老师占比', Value: 35 },
                  { Key: '学生占比', Value: 65 },
                ]"
              />
              <div class="center_title">
                <div class="titleBox">学历分布</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formartDate } from "@/utils/tools.js";
// import "@/styles/visual-analysis.scss";
import ChartModel from "./components/ChartModel1.vue";
import ArrayBar1 from "./components/ArrayBar1.vue";
import StudentBarChart from "./components/StudentBarChart.vue";
import StaffStudentChart from "./components/StaffStudentChart.vue";
import RankListChart from "./components/RankListChart.vue";
export default {
  name: "analysis",
  props: {},
  components: {
    ChartModel,
    ArrayBar1,
    StudentBarChart,
    StaffStudentChart,
    RankListChart,
  },
  data() {
    return {
      loading: false,
      scaleNum: null,
      timeData: null,
      listIcon: [
        require("@/assets/images/analysis/tag/0.png"),
        require("@/assets/images/analysis/tag/1.png"),
        require("@/assets/images/analysis/tag/2.png"),
        require("@/assets/images/analysis/tag/3.png"),
        require("@/assets/images/analysis/tag/4.png"),
        require("@/assets/images/analysis/tag/5.png"),
      ],
      projectList: [
        {
          Icon: require("@/assets/images/analysis/tag/1.png"),
          Name: "服装店1",
          Indent: 666,
          Turnover: 999,
        },
        {
          Icon: require("@/assets/images/analysis/tag/2.png"),
          Name: "服装店2",
          Indent: 666,
          Turnover: 999,
        },
        {
          Icon: require("@/assets/images/analysis/tag/3.png"),
          Name: "服装店3",
          Indent: 666,
          Turnover: 999,
        },
        {
          Icon: require("@/assets/images/analysis/tag/4.png"),
          Name: "服装店4",
          Indent: 666,
          Turnover: 999,
        },
        {
          Icon: require("@/assets/images/analysis/tag/5.png"),
          Name: "服装店5",
          Indent: 666,
          Turnover: 999,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    setInterval(() => {
      this.getTimeData();
    }, 1000);
    this.resize_window();
    window.addEventListener("resize", () => {
      this.resize_window();
    });
  },
  beforeDestroy() {
    clearInterval(this.timeData);
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.resize_window();
    });
  },
  methods: {
    getTimeData() {
      this.timeData = formartDate(new Date(), "allweek");
    },
    resize_window() {
      const w_height = Number(document.documentElement.clientHeight / 1080);
      var w_width = Number(document.documentElement.clientWidth / 1920);
      var clineRate =
        document.documentElement.clientWidth /
        document.documentElement.clientHeight;

      if (clineRate < 1920 / 1080) {
        this.scaleNum = w_width;
      } else {
        this.scaleNum = w_height;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.div-content1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // background: url("~@/assets/images/analysis/home_bg.png") no-repeat;
  // background-size: 100% 100%;
  // padding: 0px 40px 35px 40px;
  // background-color: pink;
  .visual_box {
    width: 1920px;
    display: flex;
    justify-content: center;
    margin: 0 40px;
  }
  .head {
    width: 1920px;
    height: 88px;
    background: url("~@/assets/images/analysis/head.png") no-repeat;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .head_left {
      width: 97px;
      height: 48px;
      object-fit: cover;
      cursor: pointer;
      margin: 20px 129px 0 38px;
      background: url("~@/assets/images/analysis/project/return_nor.png")
        no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url("~@/assets/images/analysis/project/return_hov.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    .head_center {
      background-size: 100%;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      padding-top: 18px;
      text-align: center;
      text-shadow: 0px 2px 8px rgba(7, 15, 64, 0.7);
    }
    .head_right {
      width: 226px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #389df6;
      line-height: 22px;
      margin: 12px 43px 0 0;
    }
  }
  .visual_box_left {
    width: 442px;
    height: 957px;
    box-sizing: border-box;
    & /deep/ .chart-container:nth-child(n + 3) {
      margin-bottom: 0;
    }
    .project_rankingList {
      width: 442px;
      height: 629px;
      background: url("~@/assets/images/analysis/project/left_bg.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      overflow: hidden;
      .title {
        width: 100%;
        height: 33px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin: 12px 0 11px;
      }
      .listBox {
        width: 100%;
        height: 114px;
        display: flex;
        align-items: center;
        box-shadow: 0px -1px 0px 0px #26388d;
        .img {
          width: 28px;
          height: 28px;
          object-fit: cover;
          margin: 0 14px;
        }
        .list_right {
          .name {
            font-size: 21px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 29px;
          }
          .text {
            .leftTxt {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #557df7;
              line-height: 20px;
            }
            .number {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .visual_box_center {
    width: 908px;
    height: 957px;
    box-sizing: border-box;
    margin: 0 24px;
    .allChart {
      width: 100%;
      height: 84px;
      display: flex;
      box-sizing: border-box;
      .chartList {
        flex: 1;
        height: 100%;
        display: flex;
        padding: 10px 0 14px 0;
        box-sizing: border-box;
        background: url("~@/assets/images/analysis/project/top_bg_little.png")
          no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        &:nth-child(3) {
          margin-right: 0 !important;
        }
        .chart_right {
          .name {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }
          .quantity {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            .number {
              font-size: 36px;
              font-family: DIN-Medium, DIN;
              font-weight: 500;
              color: #00ffff;
              line-height: 44px;
            }
          }
        }
      }
    }
    .salesVolume {
      width: 100%;
      height: 132px;
      display: flex;
      .salesVolume_left {
        &:nth-child(2) {
          margin-right: 0;
        }
        width: 442px;
        height: 132px;
        background: #111a55;
        box-shadow: -4px 0px 0px 0px rgba(56, 157, 246, 0.3);
        margin-right: 24px;
        padding: 24px;
        box-sizing: border-box;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          .quantity_txt {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 25px;
          }
          .quantity_info {
            color: #7291ee;
            margin-left: 8px;
          }
          .quantity_proportion {
            color: #5dd561;
            margin-left: 6px;
          }
          .icon {
            margin-left: 7px;
            width: 10px;
            height: 12px;
            object-fit: cover;
          }
        }
        .quantity_number {
          margin-top: 1px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          .number {
            font-size: 48px;
            font-family: DIN-Medium, DIN;
            font-weight: 500;
            line-height: 58px;
            background-image: linear-gradient(180deg, #fd951d 0%, #ff6010 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // color: transparent;
          }
        }
      }
    }
    .histogram {
      width: 100%;
      height: 816px;
      margin-top: 24px;
      box-sizing: border-box;
      background: url("~@/assets/images/analysis/center_big.png") no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      .title {
        width: 100%;
        height: 33px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin: 12px 0 11px;
      }
      .enterprise_chart {
        width: 100%;
        height: 68%;
      }
      .enterprise_list {
        width: 100%;
        height: 24%;
        padding: 0 34px 24px;
        box-sizing: border-box;
        .title {
          width: 100%;
          text-align: left;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;
        }
        .enterpriseList {
          display: flex;
          flex-wrap: wrap;
          .enterprise_log {
            width: 197px;
            height: 62px;
            .img {
              width: 100%;
              height: 100%;
            }
            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .pieBox {
      width: 100%;
      height: 374px;
      box-sizing: border-box;
      /deep/ .chart-container {
        width: 100%;
        height: 100%;
        background: url("~@/assets/images/analysis/project/center_bg.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .visual_box_right {
    width: 442px;
    height: 957px;
    box-sizing: border-box;
    & /deep/ .chart-container:nth-child(n + 3) {
      margin-bottom: 0;
    }
    .enterprise_mentor {
      width: 442px;
      height: 629px;
      background: url("~@/assets/images/analysis/project/left_bg.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      overflow: hidden;
      .title {
        width: 100%;
        height: 33px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin: 12px 0 11px;
      }
      .topChart {
        width: 100%;
        height: 40%;
      }
      .bottomChart {
        width: 100%;
        height: 40%;
      }
      .center_title {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        .titleBox {
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          background: url("~@/assets/images/analysis/project/label_bg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
.middle {
  .total {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .total-item {
      display: flex;
      align-items: center;
      .icon {
        width: 64px;
        height: 56px;
        margin-right: 24px;
        background: url("~@/assets/images/analysis/project/center_bg.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .department {
        background: url("~@/assets/images/analysis/project/center_bg.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .text {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
      }
      .number {
        font-size: 54px;
        font-family: DicotMedium;
        font-weight: 400;
        color: #28ffac;
        text-shadow: 0px 4px 12px rgba(0, 223, 255, 0.3);
        background: linear-gradient(0deg, #0049fe 0%, #00ffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .unit {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.bar-chart {
  box-sizing: border-box;
  padding: 0 40px;
  .bar-title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .bar-number {
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #00ffff;
  }
}
>>> .el-radio-button__inner {
  width: 100px;
  height: 32px;
  background: rgba(5, 11, 29, 0);
  border: 1px solid #296aee;
  color: #fff;
}
.nightingale {
  /deep/ .chart-box {
    padding: 0;
  }
}
.select-text {
  color: #fff;
}
/deep/ .el-select {
  .el-input__inner {
    background-color: rgba(33, 83, 174, 0.4);
    border: 1px solid #2153ae;
    color: #fff;
    &:hover {
      border: 1px solid #2153ae;
    }
  }
  .el-select__caret {
    color: #169aff !important ;
  }
  .el-select-dropdown__empty {
    background-color: #2153ae;
    color: #fff;
  }
  .el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #2153ae;
  }
  .el-select-dropdown {
    border-color: #020d44;
  }
  .el-select__caret {
    color: #557df7;
  }
  .el-select-dropdown__list,
  .el-select-dropdown__item {
    background-color: rgba(33, 83, 174, 1);
    color: #fff;
  }
  .el-select-dropdown__item.selected {
    color: #389df6;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #389df6;
    color: #fff;
  }
  .el-select-dropdown .el-popper {
    border: 1px solid #2153ae !important;
  }
}
</style>
